import { useCallback, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { type GridSortModel } from '@mui/x-data-grid';
import { useAtomValue, useStore } from 'jotai';
import { dataGridRowsPerPageAtom } from 'components';
import { EFilterTesters, ENormalizeGroups } from 'hooks/useAccountingFilters';
import { useSetLoading } from 'hooks/useLoading';
import { parseBigNumber, parseCountryCode, parseCurrencyNumber, parseDateToUTC, showError } from 'utils';
import { appliedFiltersAtom, type IAppliedFilters } from './accountingDataAtoms';

const GET_DATA = gql`
  query ReportTransactions(
    $filter: JSONObject
    $skip: Int
    $take: Int
    $orderBy: String
    $orderDirection: String
    $normalized: Boolean!
    $groupBy: String
    $currencyDate: DateTime
    $baseCurrency: String
  ) {
    reportTransactions(
      filter: $filter
      skip: $skip
      take: $take
      orderBy: $orderBy
      orderDirection: $orderDirection
      normalized: $normalized
      groupBy: $groupBy
      currencyDate: $currencyDate
      baseCurrency: $baseCurrency
    ) {
      items {
        transactionsCount
        country
        currency
        slotLabel
        operatorLabel
        clusterLabel
        clientLabel
        totalBuyCount
        normalizedValues @include(if: $normalized) {
          ggr
          creditTotal
          debitTotal
          refundTotal
          totalBuyBet
        }
        values {
          ggr
          creditTotal
          debitTotal
          refundTotal
          totalBuyBet
        }
      }
      summary @include(if: $normalized) {
        ggrSummary
        debitTotalSummary
        creditTotalSummary
        refundTotalSummary
        totalBuyBetSummary
        totalBuyCountSummary
        transactionsCountSummary
      }
      totalCount
      skip
      take
      normalized @include(if: $normalized)
      currencyDate @include(if: $normalized)
      baseCurrency @include(if: $normalized)
      groupBy
    }
  }
`;

interface INormalizableValues {
  ggr?: number;
  debitTotal?: number;
  refundTotal?: number;
  creditTotal?: number;
  totalBuyBet: number;
}

interface IRawRecord {
  clusterLabel?: string;
  currency: string;
  country?: string;
  operatorLabel: string;
  clientLabel?: string;
  slotLabel: string;
  transactionsCount: number;
  totalBuyCount: number;
  values: INormalizableValues;
  normalizedValues?: INormalizableValues;
}

interface ISummary {
  ggr: string;
  debitTotal: string;
  creditTotal: string;
  refundTotal: string;
  totalBuyBet: string;
  totalBuyCount: string;
  transactionsCount: string;
}

interface IRawData {
  reportTransactions: {
    items: IRawRecord[];
    skip: number;
    take: number;
    totalCount: number;
    normalized: boolean;
    baseCurrency: string;
    groupBy: ENormalizeGroups;
    summary?: {
      ggrSummary: number;
      debitTotalSummary: number;
      creditTotalSummary: number;
      refundTotalSummary: number;
      totalBuyBetSummary: number;
      totalBuyCountSummary: number;
      transactionsCountSummary: number;
    };
  };
}

interface IRowData {
  id: string;
  clusterLabel?: string;
  clientLabel?: string;
  operatorLabel: string;
  slotLabel: string;
  currency: string;
  country?: string;
  debitTotal: string;
  creditTotal: string;
  refundTotal: string;
  transactionsCount: string;
  totalBuyBet: string;
  totalBuyCount: string;
  ggr: string;
}

export const playersMap = {
  [EFilterTesters.ALL]: undefined,
  [EFilterTesters.TESTERS_ONLY]: true,
  [EFilterTesters.REAL_ONLY]: false,
};

export const parseAccountingFilters = (appliedFilters: IAppliedFilters) => {
  const filters: Record<string, object | boolean | void> = {
    country: { in: appliedFilters.countries },
    currency: { in: appliedFilters.currencies },
    slotId: { in: appliedFilters.games },
    createdAt: {
      gte: parseDateToUTC(appliedFilters.dateFrom, appliedFilters.timezone),
      lte: parseDateToUTC(appliedFilters.dateTo, appliedFilters.timezone),
    },
    tester: playersMap[appliedFilters.players],
  };
  if (appliedFilters.operators.length > 0) {
    filters.operatorId = { in: appliedFilters.operators };
  }
  if (appliedFilters.clusters.length > 0) {
    filters.clusterId = { in: appliedFilters.clusters };
  }
  if (appliedFilters.clients.length > 0) {
    filters.clientId = { in: appliedFilters.clients };
  }
  return filters;
};

const parseRawData = (data: IRawRecord[], normalizeCurrency = ''): IRowData[] => {
  return data.map((item) => {
    const values = item.normalizedValues || item.values;
    return {
      id: crypto.randomUUID(),
      clusterLabel: item.clusterLabel,
      clientLabel: item.clientLabel,
      operatorLabel: item.operatorLabel,
      slotLabel: item.slotLabel,
      currency: item.currency,
      country: item.country && parseCountryCode(item.country),
      transactionsCount: parseBigNumber(item.transactionsCount),
      totalBuyCount: parseBigNumber(item.totalBuyCount),
      totalBuyBet: parseCurrencyNumber(values.totalBuyBet, normalizeCurrency),
      debitTotal: parseCurrencyNumber(values.debitTotal, normalizeCurrency),
      creditTotal: parseCurrencyNumber(values.creditTotal, normalizeCurrency),
      ggr: parseCurrencyNumber(values.ggr, normalizeCurrency),
      refundTotal: parseCurrencyNumber(values.refundTotal, normalizeCurrency),
    };
  });
};

export const useAccountingData = () => {
  const store = useStore();
  const { startLoading, endLoading } = useSetLoading();
  const [getData] = useLazyQuery<IRawData>(GET_DATA);
  const appliedFilters = useAtomValue(appliedFiltersAtom);
  const rowsPerPage = useAtomValue(dataGridRowsPerPageAtom);

  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<GridSortModel>([]);
  const [data, setData] = useState<IRowData[]>([]);
  const [summary, setSummary] = useState<ISummary | undefined>(undefined);
  const [totalDataCount, setTotalDataCount] = useState(-1);
  const [groupBy, setGroupBy] = useState<ENormalizeGroups>(ENormalizeGroups.NONE);

  const setSortAndClearPage = useCallback((...params: Parameters<typeof setSort>) => {
    setPage(0);
    setSort(...params);
  }, []);
  useEffect(() => store.sub(appliedFiltersAtom, () => setPage(0)), []);
  useEffect(() => {
    if (appliedFilters) {
      const sortKey = sort[0]?.field;
      const normalizedSortKeys: Record<string, string> = {
        ggr: 'nGgr',
        debitTotal: 'nDebitTotal',
        creditTotal: 'nCreditTotal',
        refundTotal: 'nRefundTotal',
        totalBuyBet: 'nTotalBuyBet',
      };
      startLoading('fetchAccountingData');
      getData({
        fetchPolicy: 'network-only',
        variables: {
          filter: parseAccountingFilters(appliedFilters),
          skip: page * rowsPerPage,
          take: rowsPerPage,
          orderBy: appliedFilters.normalizeEnabled ? normalizedSortKeys[sortKey] || sortKey : sortKey,
          orderDirection: sort[0]?.sort,
          normalized: appliedFilters.normalizeEnabled,
          currencyDate: appliedFilters.currencyDate,
          groupBy:
            appliedFilters.normalizeGroupBy === ENormalizeGroups.NONE ? undefined : appliedFilters.normalizeGroupBy,
          baseCurrency: appliedFilters.normalizeCurrency,
        },
      })
        .then((response) => {
          if (response.data?.reportTransactions) {
            const { summary, totalCount, items, groupBy, baseCurrency } = response.data.reportTransactions;
            const normalizeCurrency = baseCurrency || '';
            const mappedData: IRowData[] = parseRawData(items, normalizeCurrency);
            setTotalDataCount(totalCount);
            setData(mappedData);
            setGroupBy(groupBy || ENormalizeGroups.NONE);
            setSummary(
              summary && {
                ggr: parseCurrencyNumber(summary.ggrSummary, normalizeCurrency),
                debitTotal: parseCurrencyNumber(summary.debitTotalSummary, normalizeCurrency),
                creditTotal: parseCurrencyNumber(summary.creditTotalSummary, normalizeCurrency),
                refundTotal: parseCurrencyNumber(summary.refundTotalSummary, normalizeCurrency),
                totalBuyBet: parseCurrencyNumber(summary.totalBuyBetSummary, normalizeCurrency),
                totalBuyCount: parseBigNumber(summary.totalBuyCountSummary, 0),
                transactionsCount: parseBigNumber(summary.transactionsCountSummary, 0),
              },
            );
          } else if (response.error) {
            showError(response.error);
          }
          endLoading('fetchAccountingData');
        })
        .catch((err) => {
          showError(err);
          endLoading('fetchAccountingData');
        });
    }
  }, [appliedFilters, sort, page, rowsPerPage]);

  return { data: data || [], totalDataCount, page, setPage, sort, setSort: setSortAndClearPage, summary, groupBy };
};
