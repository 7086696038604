import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { useCurrentPath } from 'hooks/useCurrentPath';

const buildTranslationKeys = (currentPath: string) => {
  const splitPath = currentPath.split('/').filter(Boolean);

  return splitPath.map((path, i) => {
    if (path.startsWith(':')) return path;

    return `navigation.${splitPath.slice(0, i + 1).join('.')}.title`;
  });
};

export const TopBar = () => {
  const { t } = useTranslation();
  const params = useParams();
  const currentPath = useCurrentPath();
  const builtBreadcrumbs = useMemo(() => buildTranslationKeys(currentPath || ''), [currentPath]);

  if (!currentPath) return null;

  return (
    <AppBar
      position="fixed"
      variant="outlined"
      elevation={0}
      sx={(theme) => ({
        background: theme.palette.background.default,
        color: 'black',
        pl: 8,
        pt: 6,
      })}
    >
      <Toolbar variant="dense">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {builtBreadcrumbs.map((breadcrumb, i) => {
            const displayText = breadcrumb.startsWith(':') ? params[breadcrumb.replace(':', '')] : t(breadcrumb);
            const isLastElement = i === builtBreadcrumbs.length - 1;

            return (
              <Box key={displayText} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography color="ButtonText" fontSize={12} fontWeight={isLastElement ? 700 : 500}>
                  {displayText}
                </Typography>
                <Typography color="ButtonText" sx={{ ml: 1 }} fontSize={12}>
                  {!isLastElement && '/'}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
