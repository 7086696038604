import { atom, useAtomValue } from 'jotai';

export enum EUserRole {
  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  ADMIN = 'ADMIN',
  AREA_ADMIN = 'AREA_ADMIN',
  SUPPORT = 'SUPPORT',
}

export enum EUserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'BLOCKED',
  PENDING = 'PENDING',
}

export interface IUser {
  id: string;
  email: string;
  status: EUserStatus;
  createdAt: string;
  lastActiveAt: string;
  role: EUserRole;
  clientsIds: string[];
  clustersIds: string[];
  operatorsIds: string[];
  permissions: string[];
  changePassword: boolean;
}

export const emptyUser = {
  id: '',
  email: '',
  status: EUserStatus.INACTIVE,
  createdAt: '',
  lastActiveAt: '',
  role: EUserRole.SUPPORT,
  clientsIds: [],
  clustersIds: [],
  operatorsIds: [],
  changePassword: false,
  permissions: [],
};

export const userAtom = atom<IUser>(emptyUser);

export const useUser = () => {
  const user = useAtomValue(userAtom);
  return user as IUser;
};
