import { useEffect } from 'react';
import { Box } from 'components';
import { useSidemenu } from 'hooks/useSideMenu';
import FirstLoginChangePassword from './FirstLoginChangePassword';

export const Dashboard = () => {
  const { setMenuOpen } = useSidemenu();

  useEffect(() => {
    setTimeout(() => {
      setMenuOpen(true);
    }, 500);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <FirstLoginChangePassword />
    </Box>
  );
};

export default Dashboard;
