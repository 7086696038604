import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { atom, useStore } from 'jotai';
import * as Yup from 'yup';
import {
  Box,
  Button,
  FormControl,
  HeaderText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from 'components';
import {
  EUserRole,
  EUsersBulkAction,
  EUserStatus,
  useLimitedFiltersData,
  useLoading,
  useUser,
  useUserManagement,
  type IAccountingFilterData,
  type IUserInput,
} from 'hooks';
import * as accountingFiltersAtoms from 'hooks/useAccountingFilters/accountingFiltersAtoms';
import { ERoutes } from 'routing/routes';
import { isSpecificError } from 'utils';
import AccountingReportFilter from 'views/AccountingReport/AccountingReportFilters/AccountingReportFilter';
import NotFound from 'views/NotFound';
import TeamMemberUserMoreButton from './TeamMemberUserMoreButton';
import TeamMemberUserPasswordPopup from './TeamMemberUserPasswordPopup';

export const TeamMemberUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const newUser = id === 'add';
  const store = useStore();
  const currentUser = useUser();
  const { rawData, updateUser, addUser, error, handleUsersBulkAction } = useUserManagement(id, newUser);
  const { loading } = useLoading();
  const [newUserData, setNewUserData] = useState<{ password: string; email: string } | null>(null);
  const navigate = useNavigate();

  const atoms = useMemo(
    () => ({
      clusters: {
        selected: atom<string[]>([]),
        data: atom<IAccountingFilterData[]>([]),
        groups: accountingFiltersAtoms.clustersAtoms.groups,
        error: atom(false),
      },
      clients: {
        selected: atom<string[]>([]),
        data: atom<IAccountingFilterData[]>([]),
        groups: accountingFiltersAtoms.clientsAtoms.groups,
        error: atom(false),
      },
      operators: {
        selected: atom<string[]>([]),
        data: atom<IAccountingFilterData[]>([]),
        groups: accountingFiltersAtoms.operatorsAtoms.groups,
        error: atom(false),
      },
    }),
    [],
  );

  useLimitedFiltersData({
    loadingKey: 'teamMemberUser',
    clustersAtoms: atoms.clusters,
    clientsAtoms: atoms.clients,
    operatorsAtoms: atoms.operators,
  });

  const handleAccept = useCallback(
    async (data: Record<string, string>) => {
      const userInput: IUserInput = {
        role: data.role as EUserRole,
        status: rawData?.user.status as EUserStatus,
        clientsIds: store.get(atoms.clients.selected),
        clustersIds: store.get(atoms.clusters.selected),
        operatorsIds: store.get(atoms.operators.selected),
      };

      if (newUser) {
        userInput.email = data.email;
        addUser(userInput).then((user) => setNewUserData(user ?? null));
      } else {
        updateUser(userInput);
      }
    },
    [rawData],
  );

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('login.notValidEmail')).required('Required'),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: { email: '', role: EUserRole.ACCOUNT_MANAGER, status: EUserStatus.PENDING },
    onSubmit: handleAccept,
  });

  useEffect(() => {
    if (rawData) {
      store.set(atoms.clusters.selected, rawData.user.clustersIds);
      store.set(atoms.clients.selected, rawData.user.clientsIds);
      store.set(atoms.operators.selected, rawData.user.operatorsIds);
      formik.setValues({ email: rawData.user.email, role: rawData.user.role, status: rawData.user.status });
    }
  }, [rawData]);

  if (
    isSpecificError(error, 'Cannot return null for non-nullable field Query.user.') ||
    rawData?.user.id === currentUser.id
  ) {
    return <NotFound overrideTitle={t('team.editUser')} overrideSubtitle={t('team.userNotFound', { id })} />;
  }

  const handleDelete = useCallback(async () => {
    if (rawData.user?.id) {
      const passed = await handleUsersBulkAction(EUsersBulkAction.DELETE, [rawData.user?.id]);
      if (passed) {
        navigate(ERoutes.ManagementTeamMembers);
      }
    }
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        left: 0,
        pb: 2,
      }}
    >
      <TeamMemberUserPasswordPopup
        userData={newUserData}
        onClose={() => setNewUserData(null)}
        title={t('team.userCreated')}
      />
      <HeaderText>{t(newUser ? 'team.addUser' : 'team.editUser')}</HeaderText>

      <form onSubmit={formik.handleSubmit}>
        <Paper sx={{ position: 'relative', p: 2, display: 'flex', gap: 2, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
            <Typography variant="h6" sx={{ flexGrow: 1, ml: 0.5 }}>
              {t('team.userDetails')}
            </Typography>
            <Button disabled={loading} type="submit">
              {t('base.accept')}
            </Button>
            {!newUser && <TeamMemberUserMoreButton disabled={loading} user={rawData?.user} deleteUser={handleDelete} />}
          </Box>
          <TextField
            size="small"
            name="email"
            label={t('login.email')}
            required
            disabled={!newUser || loading}
            sx={{ flexGrow: 1 }}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.submitCount > 0 && formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.submitCount > 0 && formik.touched.email && formik.errors.email}
          />
          <FormControl sx={{ flexGrow: 1 }}>
            <InputLabel size="small">{t('team.role')}</InputLabel>
            <Select
              size="small"
              name="role"
              value={formik.values.role}
              onChange={formik.handleChange}
              disabled={loading}
              label={t('team.role')}
            >
              <MenuItem value={EUserRole.AREA_ADMIN}>{t('team.areaAdmin')}</MenuItem>
              <MenuItem value={EUserRole.ACCOUNT_MANAGER}>{t('team.accountManager')}</MenuItem>
              <MenuItem value={EUserRole.SUPPORT}>{t('team.support')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ flexGrow: 1 }}>
            <InputLabel size="small">{t('team.status')}</InputLabel>
            <Select
              disabled={formik.values.status === EUserStatus.PENDING || loading}
              size="small"
              name="status"
              value={formik.values.status}
              onChange={formik.handleChange}
              label={t('team.status')}
            >
              <MenuItem disabled value={EUserStatus.PENDING}>
                {t('team.pending')}
              </MenuItem>
              <MenuItem value={EUserStatus.ACTIVE}>{t('team.active')}</MenuItem>
              <MenuItem value={EUserStatus.INACTIVE}>{t('team.inactive')}</MenuItem>
            </Select>
          </FormControl>
        </Paper>

        <Paper sx={{ p: 2, mt: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, position: 'relative' }}>
            {[
              { key: 'clusters', atoms: atoms.clusters },
              { key: 'clients', atoms: atoms.clients },
              { key: 'operators', atoms: atoms.operators },
            ].map(({ key, atoms }) => (
              <AccountingReportFilter key={key} title={t('accounting.' + key)} atoms={atoms} itemsVisibleCount={8} />
            ))}
          </Box>
        </Paper>
      </form>
    </Box>
  );
};

export default TeamMemberUser;
