import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, type QueryResult } from '@apollo/client';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useGridApiContext, type GridSortModel } from '@mui/x-data-grid';
import { useAtomValue } from 'jotai';
import { Box, Button, MenuItem, PopperMenu, Tooltip } from 'components';
import { ENormalizeGroups, usePerms, useReportExport } from 'hooks';
import { appliedFiltersAtom, parseAccountingFilters } from 'hooks/useAccountingData';
import { showToast } from 'utils';

const GET_CSV = gql`
  query ReportTransactionsCsv(
    $filter: JSONObject
    $orderBy: String
    $orderDirection: String
    $normalized: Boolean!
    $groupBy: String
    $currencyDate: DateTime
    $baseCurrency: String
    $columnsMappers: [ReportColumnMapperInput!]!
  ) {
    reportTransactionsCsv(
      filter: $filter
      orderBy: $orderBy
      orderDirection: $orderDirection
      normalized: $normalized
      groupBy: $groupBy
      currencyDate: $currencyDate
      baseCurrency: $baseCurrency
      columnsMappers: $columnsMappers
    ) {
      url
    }
  }
`;

const GET_XLSX = gql`
  query ReportTransactionsXlsx(
    $filter: JSONObject
    $orderBy: String
    $orderDirection: String
    $columnsMappers: [ReportColumnMapperInput!]!
  ) {
    reportTransactionsXlsx(
      filter: $filter
      orderBy: $orderBy
      orderDirection: $orderDirection
      columnsMappers: $columnsMappers
    ) {
      url
    }
  }
`;

interface IProps {
  sort: GridSortModel;
}

export const AccountingReportExport = ({ sort }: IProps) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const appliedFilters = useAtomValue(appliedFiltersAtom);
  const perms = usePerms();
  const { generatingReport, generate } = useReportExport();

  const handleClick = useCallback(
    (type: 'csv' | 'xlsx') => {
      setOpen(false);

      if (!appliedFilters) {
        showToast({ severity: 'warning', title: t('accounting.applyFiltersBeforeExport') });
        return null;
      }

      const columns = apiRef.current?.getVisibleColumns();
      const normalizedColumnsLabelMap: Record<string, string> = {
        ggr: 'accounting.exportNormalizedGgr',
        debitTotal: 'accounting.exportNormalizedTotalBet',
        creditTotal: 'accounting.exportNormalizedTotalWin',
        refundTotal: 'accounting.exportNormalizedTotalRefund',
        totalBuyBet: 'accounting.exportNormalizedTotalBuyBet',
      };
      const normalizedColumns = ['ggr', 'debitTotal', 'refundTotal', 'creditTotal', 'totalBuyBet'];
      const basePrefix = 'values.';
      const normalizedPrefix = 'normalizedValues.';
      const columnsMappers = columns.reduce(
        (acc, column) => {
          if (normalizedColumns.includes(column.field)) {
            acc.push({
              field: basePrefix + column.field,
              label: column.headerName || column.field,
            });
            if (appliedFilters.normalizeEnabled) {
              acc.push({
                field: normalizedPrefix + column.field,
                label: t(normalizedColumnsLabelMap[column.field], { currency: appliedFilters.normalizeCurrency }),
              });
            }
          } else {
            acc.push({
              field: column.field,
              label: column.headerName || column.field,
            });
          }
          return acc;
        },
        [] as { field: string; label: string }[],
      );
      const shouldAddNormalizedCurrency =
        appliedFilters.normalizeGroupBy !== ENormalizeGroups.NONE &&
        appliedFilters.normalizeGroupBy !== ENormalizeGroups.CURRENCIES;
      if (shouldAddNormalizedCurrency) {
        columnsMappers.unshift({
          field: 'normalizedValues.currency',
          label: t('accounting.normalizedCurrency'),
        });
      }

      generate(
        type === 'xlsx' ? GET_XLSX : GET_CSV,
        {
          columnsMappers,
          filter: parseAccountingFilters(appliedFilters),
          orderBy: sort[0]?.field,
          orderDirection: sort[0]?.sort,
          normalized: appliedFilters.normalizeEnabled,
          baseCurrency: appliedFilters.normalizeCurrency,
          currencyDate: appliedFilters.currencyDate,
          groupBy:
            appliedFilters.normalizeGroupBy === ENormalizeGroups.NONE ? undefined : appliedFilters.normalizeGroupBy,
        },
        (result: QueryResult) =>
          result.data?.reportTransactionsCsv?.url || result.data?.reportTransactionsXlsx?.url || '',
      );
    },
    [appliedFilters],
  );

  if (!perms.reportsAccountingExport) {
    return null;
  }

  return (
    <>
      <Tooltip title={generatingReport ? t('reportExport.onlyOneAtTime') : ''}>
        <Box>
          <Button
            ref={buttonRef}
            disabled={generatingReport}
            variant="text"
            size="small"
            onClick={() => setOpen((s) => (generatingReport ? false : !s))}
          >
            <FileDownloadIcon fontSize="small" sx={{ mr: 1 }} />
            {t('base.export')}
          </Button>
        </Box>
      </Tooltip>
      <PopperMenu anchorEl={buttonRef.current} open={open} onClose={() => setOpen(false)}>
        <MenuItem onClick={() => handleClick('csv')}>CSV</MenuItem>
        <MenuItem onClick={() => handleClick('xlsx')}>XLSX</MenuItem>
      </PopperMenu>
    </>
  );
};

export default AccountingReportExport;
