import { useTranslation } from 'react-i18next';
import { ManageSearch } from '@mui/icons-material';
import { CircularProgress, Typography } from '@mui/material';
import { DataGrid, GridOverlay, type DataGridProps } from '@mui/x-data-grid';

interface IProps extends DataGridProps {}

export const DefaultDataGrid = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <DataGrid
      disableColumnFilter
      disableRowSelectionOnClick
      sortingMode="server"
      editMode="row"
      hideFooter={props.rows?.length === 0}
      disableColumnSorting={props.loading}
      hideFooterSelectedRowCount
      {...props}
      slots={{
        noRowsOverlay: () => (
          <GridOverlay sx={{ display: 'flex', flexDirection: 'column', color: 'grey.400' }}>
            <ManageSearch sx={{ fontSize: 100 }} />

            <Typography variant="caption" fontSize={14}>
              {t('base.NoData')}
            </Typography>
          </GridOverlay>
        ),
        ...props.slots,
        loadingOverlay: () => {
          return (
            <GridOverlay>
              <CircularProgress size={30} />
            </GridOverlay>
          );
        },
      }}
      slotProps={{
        columnMenu: { slots: { columnMenuColumnsItem: null } },
        ...props.slotProps,
      }}
      disableVirtualization
    />
  );
};

export default DefaultDataGrid;
