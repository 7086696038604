import { permsAtom } from 'auth';
import { useAtomValue } from 'jotai';
import { EUserRole, type IUser } from './useUser';

export const parsePerms = (user: IUser) => {
  const arr = user.permissions;
  return {
    loaded: true,
    reportsAccounting: arr.includes('view_report_transactions'),
    reportsPlayerBrowser: arr.includes('view_combined_transactions_by_players'),
    reportsAccountingExport: arr.includes('export_report_transactions_csv'),
    team: arr.includes('view_users'),
    clusters: user.role === EUserRole.ADMIN || user.clustersIds.length > 1,
    clients: user.role === EUserRole.ADMIN || user.clientsIds.length > 1,
    operators: user.role === EUserRole.ADMIN || user.operatorsIds.length > 1,
    // todo
    reports: true,
    reportsGameBrowser: true,
  };
};

export const usePerms = () => {
  const perms = useAtomValue(permsAtom);
  return perms;
};
