import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Button, IconButton, List, ListItem, ListItemButton, ListItemText, PopperMenu, Tooltip } from 'components';
import en from './flags/en.svg';
import ja from './flags/ja.svg';
import test from './flags/test.svg';
import LanguageSelectorAvatar from './LanguageSelectorAvatar';

const options = [
  { label: 'English', key: 'en-US', flag: en },
  { label: 'Japan', key: 'ja-JP', flag: ja },
];

if (import.meta.env.NODE_ENV === 'DEVELOPMENT') {
  options.push({ label: 'Test', key: 'cimode', flag: test });
}

interface IProps {
  variant?: 'button' | 'icon';
}

export const LanguageSelector = ({ variant }: IProps) => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const rootRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const handleChange = useCallback(
    (key: string) => {
      setOpen(false);
      i18n.changeLanguage(key);
    },
    [i18n],
  );

  const currentOption = options.find((option) => option.key === i18n.language) || options[0];

  return (
    <>
      {variant === 'icon' ? (
        <Tooltip title={!open && t('menu.changeLanguage')}>
          <IconButton color="inherit" onClick={() => setOpen(true)} ref={rootRef}>
            <LanguageSelectorAvatar src={currentOption.flag} alt={currentOption.label} />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="text"
          onClick={() => setOpen(true)}
          sx={{ display: 'flex', alignItems: 'center', color: 'text.primary' }}
          ref={rootRef}
        >
          <LanguageSelectorAvatar src={currentOption.flag} alt={currentOption.label} shadow />
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Button>
      )}

      <PopperMenu
        open={open}
        anchorEl={rootRef.current}
        onClose={() => setOpen(false)}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 3 }}
      >
        <List>
          {options.map((option) => (
            <ListItem key={option.key} dense sx={{ padding: 0 }}>
              <ListItemButton
                dense
                onClick={() => handleChange(option.key)}
                sx={{
                  display: 'flex',
                  gap: 1.5,
                }}
              >
                <LanguageSelectorAvatar src={option.flag} alt={option.label} shadow />
                <ListItemText primary={option.label} sx={{ marginLeft: 1 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </PopperMenu>
    </>
  );
};

export default LanguageSelector;
