import { useRef, useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useAtom, useAtomValue } from 'jotai';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from 'components';
import { type IAccountingFilterData, type TFilterAtoms } from 'hooks/useAccountingFilters';

const ITEM_HEIGHT = 24;

const AccountingReportListItem = ({
  item,
  onClick,
  initialChecked,
  shouldSkipAnimation,
}: {
  item: IAccountingFilterData;
  onClick: () => void;
  initialChecked: boolean;
  shouldSkipAnimation: (item: IAccountingFilterData) => boolean;
  itemsVisibleCount?: number;
}) => {
  const [touched, setTouched] = useState(false);

  return (
    <ListItem
      disablePadding
      sx={{
        p: '0 6px',
        height: touched ? 0 : ITEM_HEIGHT,
        transition: 'transform 1000ms, opacity 1000ms, height 600ms, background-color 600ms',
        opacity: touched ? 0 : 1,
        transform: `translateY(${touched ? 50 * (initialChecked ? 1 : -1) : 0}px)`,
        userSelect: 'none',
      }}
      onClick={() => {
        if (shouldSkipAnimation(item)) {
          onClick();
        } else {
          setTouched(true);
          setTimeout(onClick, 500);
        }
      }}
    >
      <ListItemButton sx={{ p: 0 }} title={item.name}>
        <ListItemIcon sx={{ minWidth: 24 }}>
          {((!initialChecked && !touched) || (initialChecked && touched)) && (
            <CheckBoxOutlineBlankIcon color="action" fontSize="small" />
          )}
          {((initialChecked && !touched) || (!initialChecked && touched)) && (
            <CheckBoxIcon color="primary" fontSize="small" />
          )}
        </ListItemIcon>
        <ListItemText primary={item.name} primaryTypographyProps={{ variant: 'body2', noWrap: true }} />
      </ListItemButton>
    </ListItem>
  );
};

interface IProps {
  atoms: TFilterAtoms;
  dataToShow: IAccountingFilterData[];
  itemsVisibleCount?: number;
}

export const AccountingReportFiltersList = ({ atoms, dataToShow, itemsVisibleCount = 12 }: IProps) => {
  const [selected, setSelected] = useAtom(atoms.selected);
  const isError = useAtomValue(atoms.error);
  const listRef = useRef<HTMLUListElement>(null);
  const listHeight = ITEM_HEIGHT * itemsVisibleCount;

  return (
    <List
      disablePadding
      ref={listRef}
      sx={{
        height: isError ? listHeight - ITEM_HEIGHT : listHeight,
        overflowY: 'auto',
        border: '1px solid',
        borderColor: 'divider',
        position: 'relative',
      }}
    >
      {dataToShow.map(
        (item) =>
          selected.includes(item.id) && (
            <AccountingReportListItem
              initialChecked={true}
              key={item.id}
              item={item}
              onClick={() => setSelected(selected.filter((id) => id !== item.id))}
              shouldSkipAnimation={(item) => {
                const scrollTop = listRef.current?.scrollTop || 0;
                let targetIndex = selected.length - 1;
                dataToShow.find((el) => {
                  if (!selected.includes(el.id)) {
                    targetIndex++;
                  }
                  return el === item;
                });
                const targetPosition = targetIndex * ITEM_HEIGHT;
                return targetPosition >= scrollTop && targetPosition <= scrollTop + listHeight;
              }}
            />
          ),
      )}
      {dataToShow.map(
        (item) =>
          !selected.includes(item.id) && (
            <AccountingReportListItem
              initialChecked={false}
              key={item.id}
              item={item}
              onClick={() => setSelected([...selected, item.id])}
              shouldSkipAnimation={(item) => {
                const scrollTop = listRef.current?.scrollTop || 0;
                let targetIndex = 0;
                dataToShow.find((el) => {
                  if (selected.includes(el.id)) {
                    targetIndex++;
                  }
                  return el === item;
                });
                const targetPosition = targetIndex * ITEM_HEIGHT;
                return targetPosition >= scrollTop && targetPosition <= scrollTop + listHeight;
              }}
            />
          ),
      )}
    </List>
  );
};

export default AccountingReportFiltersList;
